.popular {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 90vh;
}

.popular h1 {
  color: var(--black);
  font-size: 50px;
  font-weight: 600;
}

.popular hr {
  width: 200px;
  height: 6px;
  border-radius: 10px;
  background: #252525;
}

.popular-item {
  margin-top: 50px;
  display: flex;
  gap: 30px;
}

/* media queries */
@media (max-width: 1280px) {
  .popular {
    height: 70vh;
  }

  .popular h1 {
    font-size: 40px;
  }

  .popular hr {
    width: 160px;
    height: 4px;
  }

  .popular-item {
    gap: 20px;
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .popular {
    height: 50vh;
  }

  .popular h1 {
    font-size: 30px;
  }

  .popular hr {
    width: 120px;
    height: 3px;
  }

  .popular-item {
    gap: 15px;
    margin-top: 20px;
  }
}

@media (max-width: 800px) {
  .popular {
    height: 35vh;
    gap: 6px;
  }

  .popular h1 {
    font-size: 20px;
  }

  .popular hr {
    width: 100px;
  }

  .popular-item {
    gap: 5px;
  }
}

@media (max-width: 500px) {
  .popular {
    height: 90vh;
  }

  .popular-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
