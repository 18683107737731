.cartitems {
  margin: 100px 170px;
}

.cartitems hr {
  height: 3px;
  background: #e2e2e2;
  border: 0;
}

.cartitems-format-main {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 75px;
  padding: 20px 0;
  color: #454545;
  font-size: 18px;
  font-weight: 600;
}

.cartitems-format {
  font-size: 17px;
  font-weight: 500;
}

.carticon-product-icon {
  height: 62px;
}

.cartitems-remove-icon {
  width: 15px;
  margin: 0 40px;
  cursor: pointer;
}

.cartitems-quantity {
  width: 64px;
  height: 50px;
  border: 2px solid #ebebeb;
  background: #fff;
}

.cartitems-down {
  display: flex;
  margin: 100px 0;
}

.cartitems-total {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 200px;
  gap: 40px;
}

.cartitems-total-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.cartitems-total button {
  width: 262px;
  height: 58px;
  outline: none;
  border: none;
  background: #ff5a5a;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.cartitems-promocode {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
}

.cartitems-promocode p {
  color: #555;
}

.cart-items-promobox {
  width: 504px;
  margin-top: 15px;
  padding-left: 20px;
  height: 58px;
  background: #eaeaea;
}

.cart-items-promobox input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  width: 330px;
  height: 50px;
}

.cart-items-promobox button {
  width: 170px;
  height: 58px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

/* media queries */

@media (max-width: 1280px) {
  .cartitems {
    margin: 60px 50px;
  }

  .cartitems-format-main {
    grid-template-columns: 0.5fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 20px;
    padding: 15px 0;
    font-size: 15px;
  }

  .carticon-product-icon {
    height: 50px;
  }

  .cartitems-remove-icon {
    margin: auto;
  }

  .cartitems-quantity {
    width: 40px;
    height: 30px;
  }

  .cartitems-down {
    margin: 80px 0;
    flex-direction: column;
    gap: 80px;
  }

  .cartitems-total {
    margin: 0;
  }

  .cartitems-total button {
    max-width: 200px;
    height: 45px;
    font-size: 13px;
  }

  .cart-items-promobox {
    width: auto;
    max-width: 500px;
  }

  .cart-items-promobox input {
    width: 100%;
  }

  .cart-items-promobox button {
    width: 120px;
    margin-left: -125px;
  }
}

@media (max-width: 500px) {
  .cartitems-format-main {
    display: none;
    grid-template-columns: 0.5fr 3fr 0.5fr;
    gap: 10px;
  }

  .cartitems-format {
    display: grid;
  }
}
