.swipe {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: row;
}

.swiper-horizontal {
  overflow: visible;
}

.hero {
  height: 100dvh;
  background: linear-gradient(180deg, #ecc26e, #e1ffea22 60%);
  display: flex;
}

.hero-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-left: 180px;
  line-height: 1.1;
}

.hero-left h2 {
  color: #090909;
  font-size: 26px;
  font-weight: 600;
}

.hero-left p {
  color: var(--black);
  font-size: 100px;
  font-weight: 700;
}

.hero-latest-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 310px;
  height: 70px;
  border-radius: 75px;
  margin-top: 30px;
  background: var(--gold);
  color: white;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
}

.hero-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-right img {
  width: 450px;
}

/* media queries */
@media (max-width: 1280px) {
  .hero-left {
    padding-left: 100px;
  }

  .hero-left h2 {
    font-size: 22px;
  }

  .hero-left p {
    font-size: 60px;
  }

  .hero-latest-btn {
    gap: 10px;
    width: 250px;
    height: 60px;
    margin: 20px;
    font-size: 18px;
  }
  .hero-right img {
    width: 500px;
  }
}

@media (max-width: 1024px) {
  .hero-left {
    padding-left: 80px;
  }

  .hero-left h2 {
    font-size: 20px;
  }

  .hero-left p {
    font-size: 50px;
  }

  .hero-latest-btn {
    width: 220px;
    height: 50px;
    font-size: 16px;
  }
  .hero-right img {
    width: 400px;
  }
}

@media (max-width: 800px) {
  .hero-left {
    padding-left: 30px;
  }

  .hero-left h2 {
    font-size: 16px;
  }

  .hero-left p {
    font-size: 40px;
  }

  .hero-latest-btn {
    width: 175px;
    height: 40px;
    font-size: 13px;
  }
  .hero-right img {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .hero {
    flex-direction: column;
  }

  .hero-right {
    display: none;
  }

  .hero-left h2 {
    font-size: 18px;
  }

  .hero-left p {
    font-size: 50px;
  }

  .hero-latest-btn {
    width: 200px;
    height: 45px;
    font-size: 14px;
  }
}
